import "../contacto/contacto.scss";

import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import SectionLanding from "../../components/section/SectionLanding";
import UneteFormFiles from "../../components/contact/UneteFormFiles";

// import UneteForm from "../../components/contact/UneteForm";

export default function IndexPage() {
	const { t } = useTranslation();

	return (
		<Layout className="invertColors noBg">
			<Seo title={t("Únete al equipo")} />

			<div id="unete" className="section splitSection">
				<div className="titleContainer">
					<StaticImage
						src={`../../images/unete.jpg`}
						className="landing-bg"
						alt="Section background"
						layout="fullWidth"
					/>

					<div className="landingSection">
						<SectionLanding
							className="darkText"
							title={
								<Trans i18nKey="titleUnete">
									¿Quieres unirte al <i>equipo</i>?
								</Trans>
							}
							orbitalText={t("Bienvenido")}
						/>
					</div>
				</div>
				<div className="contentsContainer section invertColors">
					<div className="contactoBody">
						<div>
							<p className="h2">
								<Trans>
									Si quieres aportar tu granito de arena a este proyecto y
									entrar a formar parte de nuestro equipo, ¡estamos esperándote!
								</Trans>
							</p>
							<p className="h2">
								<Trans>Cuéntamos sobre ti, ¡estamos deseando conocerte!</Trans>
							</p>
						</div>
						<div>
							<UneteFormFiles />
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
